<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Archived Templates</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Templates"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn depressed light :to="{ name: 'module-graham-templates' }">
              <v-icon small left>mdi-arrow-left</v-icon> Back to Templates
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="templates"
        class="mt-4"
        no-data-text="No Archived Templates found"
      >
        <template v-slot:item.created_at="{ item }">
          {{ formatDateTime(item.created_at) }}
        </template>

        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'module-graham-templates-individual',
              params: { templateId: item.id },
            }"
            >GPC{{ item.id }} - {{ item.name }}</router-link
          >
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            v-if="item.status === 'processing'"
            color="blue"
            text-color="white"
            >Processing</v-chip
          >
          <v-chip
            label
            v-else-if="item.status === 'pending-payment'"
            color="warning"
            >Pending Payment</v-chip
          >
          <v-chip label v-else-if="item.status === 'cancelled'"
            >Cancelled</v-chip
          >
          <v-chip label v-else-if="item.status === 'complete'" color="success"
            >Complete</v-chip
          >
        </template>
        <template v-slot:item.total="{ item }"> £{{ item.total }} </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="yellow lighten-4 yellow--text text--darken-3"
                v-on="on"
                @click="openRestore(item)"
              >
                <v-icon small>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Restore</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="restoreDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Restore Template</v-card-title>
        <v-card-text
          >Are you sure you wish to restore this template?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetRestore"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="restoreDialog.loading"
            @click="saveRestore"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [],
      searchTerm: "",
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Price", value: "price" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      restoreDialog: {
        open: false,
        loading: false,
        template: {},
      },
    };
  },

  mounted() {
    this.breadcrumbs.push(
      {
        text: "Templates",
        disabled: false,
        exact: true,
        to: {
          name: "module-graham-templates",
        },
      },
      {
        text: "Archived",
        disabled: true,
      }
    );
  },

  computed: {
    templates() {
      let templates = this.$store.state.graham.templates["archived"];

      if (this.searchTerm !== "") {
        templates = templates.filter((p) => {
          const template_name = p.template_name.toLowerCase();
          const description = p.description ? p.description.toLowerCase() : "";
          const type = p.type.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            template_name.includes(searchTerm) ||
            description.includes(searchTerm) ||
            type.includes(searchTerm)
          );
        });
      }

      return templates;
    },
  },
  methods: {
    openRestore(template) {
      this.restoreDialog.template = template;
      this.restoreDialog.open = true;
    },

    resetRestore() {
      this.restoreDialog.loading = false;
      this.restoreDialog.open = false;
      this.restoreDialog.template = {};
    },

    saveRestore() {
      const appId = this.$route.params.id;
      this.restoreDialog.loading = true;

      this.$store
        .dispatch("graham/templates/restoreTemplate", {
          appId,
          templateId: this.restoreDialog.template.id,
        })
        .then(() => this.resetRestore())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
